@import 'src/styles/breakpoints.module';

.card {
  padding: 0;
  color: var(--color-text-primary);
  text-align: center;
  border-inline-end: none;
  @include tablet {
    padding: 30px;
    &:nth-of-type(1),
    &:nth-of-type(3),
    &:nth-of-type(5) {
      border-inline-end: 1px solid var(--palette-gray-600);
    }
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      border-bottom: 1px solid var(--palette-gray-600);
    }
  }
  @include desktop {
    padding: 30px 12px;
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      border-inline-end: 1px solid var(--palette-gray-600);
    }
    &:nth-of-type(3) {
      border-inline-end: none;
    }
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      border-bottom: 1px solid var(--palette-gray-600);
    }
    &:nth-of-type(4) {
      border-bottom: none;
    }
  }
}

.cardIcon {
  width: 64px;
  height: 64px;
  margin: 0 auto 12px;

  @include tablet {
    margin: 0 auto 16px;
    width: 80px;
    height: 80px;
  }
  @include desktop {
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.cardTitle {
  color: var(--color-text-primary);
  margin-bottom: 8px;
  @include tablet {
    margin-bottom: 16px;
  }
}

.cardBody {
  @include desktop {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
  }
}

//.cardDescription {
//  p {
//    color: var(--color-text-secondary);
//
//  }
//}
