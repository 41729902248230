@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.riskWarning {
  &:not(:first-child) {
    padding-top: 12px;
  }

  &:not(:last-child) {
    padding-bottom: 12px;
  }

  .inner {
    position: relative;
  }

  p {
    @include tablet {
      padding-inline-end: 30px;
    }
    @include tablet {
      text-align: center;
    }
  }

  .content {
    color: var(--color-text-primary);
    display: inline-block;
    margin-top: 4px;
    @include bodyXS_;
    @include tablet {
      display: inline;
      margin-top: 0;
      @include bodyS_;
    }
  }

  br {
    @include tablet {
      display: none;
    }
  }

  .title {
    padding-inline-end: 28px;
    line-height: 25px;
    @include tablet {
      line-height: 24px;
      padding-inline-end: 0px;
    }
    color: var(--risk-warning-title-color);
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    inset-inline-end: 0px;
  }
}
