@import './../styles.module';
@import '@/styles/typography.module';

.cellAsset {
  @include desktop {
    max-width: 190px;
  }

  & > *:first-child {
    flex-shrink: 0;
    margin-inline-end: 8px;
    display: block;
  }

  & > *:last-child {
    overflow: hidden;
  }
}

.assetFullName {
  display: block;
  font-weight: var(--palette-font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
}

.assetName {
  display: block;
  font-weight: var(--palette-font-weight-regular);
  overflow: hidden;
  text-overflow: ellipsis;
}
