.icon {
  width: 80px;
  height: 80px;
  color: var(--palette-white);

  svg {
    width: 100%;
    height: auto;
  }
}
