@import '@/styles/breakpoints.module';

.badgesList {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;

  @include tablet {
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  a {
    display: block;
  }
}
