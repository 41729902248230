@import '@/styles/breakpoints.module';

.wrapper {
  border-radius: var(--palette-border-radius-m);
  background: var(--palette-gray-100);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .contactTitle{
    font-weight: var(--palette-font-weight-semibold);
    margin-bottom: 4px;
  }

  .content{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 24px;
  }

  .wrapperTitle{
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--palette-gray-150);

    @include tablet{
      padding-bottom: 16px;
    }

    @include desktop{
      padding-bottom: 24px;
    }
  }

  @include tablet{
    padding: 24px;
    gap: 16px;
  }

  @include desktop{
    padding: 40px;
    gap: 24px;
  }
}
