@import '@/styles/breakpoints.module';

.heading {
  @include desktop {
    flex-shrink: 0;
    max-width: 750px;
    width: 100%;
  }

  a {
    width: 100%;
    margin-top: 24px;
    @include tablet {
      margin-top: 32px;
      width: auto;
    }
  }
}

.headingWrapper {
  display: flex;
}

.headingImageDecorationWrapper {
  display: none;
  @include desktop {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    flex-shrink: 1;
  }

  svg {
    width: 78px;
    height: 61px;
    color: var(--palette-primary-700);
  }
}

.vertical {
  @include tablet {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .headingImageDecorationWrapper {
      margin-top: 30px;
      justify-content: center;

      svg {
        width: auto;
        height: auto;
        color: var(--palette-primary-700);
      }
    }
  }
}
