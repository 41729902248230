@import '@/styles/breakpoints.module';

.cardWrapper {
  border-radius: var(--palette-border-radius-m);

  background-color: var(--palette-white);

  display: flex;

  flex-direction: column;

  gap: 12px;

  padding: 16px;

  width: 100%;
  height: var(--card-height, 100%);

  transition:
    background-color 40ms ease-in-out,
    box-shadow 40ms ease-in-out,
    color 40ms ease-in-out;

  &:hover {
    background-color: var(--palette-primary-75);
    box-shadow: 0 0 0 1px var(--palette-primary-100);
    p {
      color: var(--palette-primary-700);
    }
  }

  &:active {
    background-color: var(--palette-primary-100);
  }

  @include tablet {
    max-width: unset;
    gap: 16px;
    padding: 24px;
  }

  @include desktop {
    max-width: 262px;
    padding: 24px 12px;
  }
}

.cardIconWrapper {
  color: var(--palette-white);

  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    width: 100%;
    height: 100%;
  }
}

.cardText {
  height: 100%;
  font-weight: var(--palette-font-weight-semibold);
}

.linkWrapper {
  padding: 0;
  width: fit-content;
}

.cardLink > * {
  display: flex;
  align-items: center;
  gap: 4px;
}
