@import '@/styles/breakpoints.module';

.spacing {
  margin-top: 80px;
  margin-bottom: 80px;

  @include tablet {
    margin-top: 96px;
    margin-bottom: 96px;
  }

  @include desktop {
    margin-top: 200px;
    margin-bottom: 200px;
  }
}

.considerSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include tablet {
    gap: 48px;
  }

  @include desktop {
    gap: 60px;
  }
}

.cards {
  display: flex;
  gap: 16px;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
}

.cardBody {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 24px;
}

.icon {
  width: 80px;
  height: 80px;
  color: var(--palette-white);

  svg {
    width: 100%;
    height: auto;
  }
}
