@import '@/styles/breakpoints.module';
@import '@/styles/padding.module';

.panel {
  border-radius: 12px;
  background: var(--palette-white);
  overflow: hidden;
  @include desktop {
    @include innerContentMaxWidth(var(--inner-content-max-width, none));
  }
}
