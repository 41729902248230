@import '@/styles/breakpoints.module';

.cards {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include tablet {
    flex-direction: row;
  }

  @include desktop {
    gap: 30px;
  }

  li {
    border: none;
  }
}
