@import '@/styles/breakpoints.module';

.post.post.post {
  img {
    object-position: top;
    object-fit: cover;

    max-height: 206px;
    min-height: 206px;

    @include tablet {
      max-height: 222px;
      min-height: 222px;
    }
  }

  max-width: unset;

  a:first-child {
    padding-bottom: 206px;

    @include tablet {
      padding-bottom: 222px;
    }
  }
}
