@import '@/styles/breakpoints.module';

.table {
  width: 100%;

  .wrapperHeaders {
    span {
      overflow: hidden;
      word-break: break-word;
      white-space: pre-wrap;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  th,
  td {
    text-align: start;
    vertical-align: middle;
    padding: 0 4px;
  }

  thead th {
    padding-bottom: 8px;
    color: var(--palette-gray-400);
  }

  tbody tr {
    height: 56px;
    color: var(--color-text-primary);
    font-weight: var(--palette-font-weight-medium);

    &:not(:last-child) {
      td,
      th {
        border-bottom: 1px solid var(--colors-grey-100, #f0f0f0);
      }
    }

    th {
      font-weight: var(--palette-font-weight-regular);

      div {
        display: flex;
        align-items: center;

        svg {
          margin-inline-end: 8px;
        }
      }
    }
  }
}
