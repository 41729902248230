@import '@/styles/breakpoints.module';

.wrapperContent {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--cards-grid-gap);

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
}

.wrapperContentItem {
  max-width: 100%;
  width: 100%;
  border-radius: var(--palette-border-radius-xm);
  background: var(--palette-gray-70);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.3s var(--transition-fn-smooth-in-out);

  &:hover {
    background: var(--palette-gray-700);

    .wrapperImg {
      transform: scale(1.05);
    }

    .itemDescription {
      .wrapperArrow {
        width: 40px;
      }

      .itemTitle {
        color: var(--palette-white);
      }

      .itemSubtitle {
        color: var(--palette-gray-200);
      }
    }
  }

  .itemDescription {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    flex-shrink: 0;
    max-width: 65%;

    .wrapperArrow {
      display: inline-flex;
      align-items: center;
      width: 32px;
      height: 32px;
      transition: all 0.3s ease;

        img,
        svg {
          width: 100%;
          height: 100%;
        }
      }

    .itemTitle {
      color: var(--palette-gray-900);
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 4px;

        img,
        svg {
          color: var(--color-accent-heading-fragment);
        }

      @include desktop {
        font-size: 28px;
      }
    }

    .itemSubtitle {
      color: var(--color-one-row-benefits-separator);
    }

    @include desktop {
      padding: 24px;
      gap: 12px;
    }
  }

  .wrapperImg {
    align-self: flex-end;
    max-width: 98px;
    max-height: 116px;
    transition: all 0.3s ease;

      img,
      svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

    @include tablet {
      max-width: 187px;
      max-height: 187px;
    }
  }

  @include tablet {
    max-width: 555px;
  }
}

.scrollTop {
  scroll-margin-top: 20px;
}

.wrapperCtaInfo {
  p {
    span {
      font-weight: var(--palette-font-weight-semibold);
      color: var(--palette-white);
    }
  }
}

.ctaLink {
  width: 100%;
  @include tablet {
    width: auto;
  }
}
