@import '@/styles/breakpoints.module';
@import '@/styles/padding.module';

.card {
  .cardHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h2 {
      font-weight: var(--palette-font-weight-semibold);
      color: var(--color-text-primary);
    }
  }
}

.bordered {
  border: 1px solid var(--palette-gray-100);
}

.regularPadding {
  padding: 16px;
  @include desktop {
    padding: 16px 12px;
  }
}

.smallPadding {
  padding: 16px 8px;
  @include tablet {
    padding: 16px;
  }
  @include desktop {
    padding: 16px 8px;
  }
}

.verticalPadding {
  padding: 16px 0;
}
