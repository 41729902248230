@import '@/styles/breakpoints.module';

.tradeOptionsList {
  li {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    column-gap: 12px;
    padding-bottom: 12px;
    svg,
    img {
      cursor: pointer;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
      border-bottom: 1px solid var(--palette-gray-100);
    }
    & > :first-child {
      display: flex;
      gap: 4px;
      font-weight: var(--palette-font-weight-medium);
      align-items: center;
    }
    & > :last-child {
      grid-row: 1 / span 2;
      grid-column: 2;
    }
  }
}

.optionTitle {
  color: var(--palette-gray-900);
}
