@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';
@import '@/styles/link.module';

.postsGrid {
  justify-content: center;
}

.card,
.post {
  transition: border-color 0.3s var(--transition-fn-ease-out-cubuc);
  border: 1px solid transparent;

  img {
    transition: filter 0.2s var(--transition-fn-ease-out-cubuc);
  }

  &.card img:hover,
  &.post:hover img {
    filter: brightness(0.8);
  }

  &:hover {
    border: 1px solid var(--palette-primary-700);

    h2,
    h3 {
      color: var(--palette-primary-700);
    }
  }
}

.postSource {
  display: inline-block;
  @include bodyS_;
  @include textLink_;
}

// BigPostCard
.card {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-radius: var(--palette-border-radius-m);
  overflow: hidden;
  background-color: var(--color-card-bg);
  box-shadow: 2px 4px 30px 0 var(--color-shadow-base);

  padding: 12px;
  padding-top: 16px;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @include tablet {
      gap: 24px;
    }

    @include desktop {
      gap: 16px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: 1088px;

      & > *:first-child {
        flex: 1.1;
        max-width: 648px;
      }
      & > *:last-child {
        flex: 1;
        max-width: 400px;
      }
    }
  }

  .content {
    @include tablet {
      padding: 0 16px;
    }

    @include desktop {
      padding: 0;
    }

    time {
      color: var(--palette-gray-400);
    }

    // text- overflow styles
    .title,
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: stretch;
    }

    .title {
      margin-top: 16px;
      color: var(--color-text-primary);
      font-weight: var(--palette-font-weight-bold);
      -webkit-line-clamp: 4;
      transition: color 0.2s;
      transition-timing-function: var(--transition-fn-ease-out-cubuc);
    }

    .description {
      -webkit-line-clamp: 3;
    }

    .readMoreLink {
      margin-top: 16px;
      @include tablet {
        margin-top: 20px;
      }
    }
  }

  .thumbnail {
    display: block;
    border-radius: var(--palette-border-radius-s);
    overflow: hidden;
    max-height: 310px;
    @include tablet {
      border-radius: var(--palette-border-radius-ml);
    }

    img {
      width: 100%;
      max-height: 310px;
      object-fit: cover;
      object-position: top;
    }
  }

  .postSource {
    margin: 16px 0;
    cursor: auto;
  }
}

// Regular Post Card
.post {
  display: block;
  width: 100%;
  //max-width: 335px;
  border-radius: var(--palette-border-radius-m);
  overflow: hidden;
  isolation: isolate;
  background-color: var(--color-card-bg);
  box-shadow: 2px 4px 30px 0 var(--color-shadow-base);

  @include tablet {
    max-width: unset;
  }

  @include desktop {
    max-width: 360px;
  }

  &:hover {
    .title {
      color: var(--palette-primary-700);
    }
  }

  .date {
    color: var(--palette-gray-400);
  }

  .title {
    color: var(--color-text-primary);
    font-weight: var(--palette-font-weight-semibold);
    margin-top: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.2s;
    transition-timing-function: var(--transition-fn-ease-out-cubuc);
  }

  .description {
    //margin-top: 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 2lh;
  }

  .content {
    padding: 16px;

    @include tablet {
      padding: 24px;
    }
  }

  .thumbnail {
    width: 100%;
    max-height: 222px;
    object-fit: cover;
    object-position: top;
  }

  .postSource {
    margin: 8px 0;
  }
}
