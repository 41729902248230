@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.disclaimer {
  margin-top: 12px;

  @include tablet {
    margin-top: 16px;
  }
}
