@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.section.section {
  a,
  a > * {
    width: 100%;
    max-width: none;
  }

  .hoverEffect {
    transition: transform 0.3s ease;

    &:hover {
      cursor: pointer;
      transform: scale(1.035);
      .wrapperCards.wrapperCards {
        svg,
        img {
          width: calc(100% + 3px);
          height: calc(100% + 3px);
        }
      }
    }
  }

  .wrapperCards.wrapperCards {
    max-width: 64px;
    height: 64px;

    &:hover {
      cursor: pointer;
      transform: scale(1.035);
    }

    svg,
    img {
      width: 100%;
      height: 100%;
      color: var(--palette-gray-200);
    }

    @include tablet {
      max-width: 80px;
      height: 80px;
    }
  }
}
