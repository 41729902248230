.cellNarrow {
  width: 1%;
}

.cell {
  padding-inline: var(--padding-table-cell);
  display: flex;
  white-space: nowrap;
  align-items: center;
}
