@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.layoutSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--vstack-spacing-sm);

  @include tablet {
    gap: var(--vstack-spacing-md);
  }

  @include desktop {
    gap: 30px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.stepsCardWrapper {
  width: 100%;

  @include desktop {
    max-width: 622px;
  }
}

.imageBlock {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 457px;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.btnWrapper {
  margin-top: 32px;

  @include tablet {
    margin-top: 48px;
  }

  @include desktop {
    margin-top: 56px;
  }

  a {
    width: 100%;
    @include tablet {
      width: unset;
    }
  }
}

.steps.steps {
  margin-top: 16px;
  margin-bottom: 16px;

  @include tablet {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @include desktop {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
