@import '/src/styles/breakpoints.module';

.wrapper {
  display: grid;
  grid-template-columns: 1fr;

  .line {
    border-bottom: 1px solid var(--color-one-row-benefits-separator);
    border-inline-end: 1px solid transparent;

    @include tablet {
      border-inline-end: 1px solid var(--color-one-row-benefits-separator);
      border-bottom: 1px solid transparent;
    }
  }

  & .infoItem:first-child {
    padding-bottom: 16px;
    padding-inline-end: 0;
    @include tablet {
      padding-bottom: 0;
      padding-inline-end: 12px;
    }
  }


  & .infoItem:last-child {
    padding-inline-start: 0;
    padding-top: 16px;
    @include tablet {
      padding-top: 0;
      padding-inline-start: 12px;
    }
  }

  .infoItem {
    text-align: center;
    padding: 16px 0;

    .itemTitle {
      font-size: 28px;
      margin-bottom: 4px;

      @include desktop {
        font-size: 48px;
        margin-bottom: 8px;
      }
    }

    @include tablet {
      padding: 0 12px;
    }
  }

  @include tablet {
    grid-template-columns: repeat(4, 1fr);
  }
}
