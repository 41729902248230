@import '@/styles/breakpoints.module';

.mainCardChart {
  border-radius: 12px;
  border: 1px solid  var(--color-tab-border);
  background:  var(--palette-white);
  box-shadow: var(--shadow-converters-table);
  height: 522px;
  padding: 16px 16px 40px 16px;

  @include tablet{
    padding: 24px 24px 40px 24px;
  }

  @include desktop{
    padding: 40px;
  }
}

.sectionTitle {
  margin-bottom: 32px;
  text-align: center;
  @include tablet {
    margin-bottom: 48px;
  }
  @include desktop {
    margin-bottom: 60px;
  }
}
