@import '@/styles/breakpoints.module';

.oneCardLayout {
  display: flex;
  flex-direction: column;
  gap: var(--vstack-spacing-sm);

  @include tablet {
    gap: 30px;
    flex-direction: row;
    justify-content: space-between;
    & > :first-child {
      max-width: 525px;
      width: 100%;
    }
  }
}
