@import '@/styles/breakpoints.module';

.wrapper {
  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 1440px;
    overflow: hidden;

    .textWrapper {
      padding-inline-start: 16px;
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      .textContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        min-width: 100%;
        align-items: flex-end;

        .bottomInfo {
          width: 100%;
          @include desktop {
            display: none;
          }
        }

        .imageMobile {
          margin-top: 24px;
          width: 100%;
          display: block;
          position: relative;
          overflow: hidden;
          order: 1;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 30px;
            background: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
            pointer-events: none;
            @include tablet {
              width: 85px;
            }
          }

          &::before {
            content: '';
            display: block;
            padding-bottom: 62.6%;
          }

          img,
          svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          @include tablet {
            order: 0;
            &::before {
              padding-bottom: 62.6%;
            }
          }

          @include desktop {
            display: none;
          }
        }

        @include desktop {
          width: initial;
          padding-top: 10px;
        }
      }

      .text {
        padding-inline-end: 16px;
        max-width: 100%;
        width: 100%;

        .ctaButton {
          margin-top: 16px;
          @include tablet {
            margin-top: 24px;
          }
          @include desktop {
            margin-top: 32px;
          }
        }

        @include tablet {
          padding-inline-end: 40px;
        }

        @include desktop {
          padding-inline-end: 16px;
          max-width: 570px;
        }
      }

      @include tablet {
        padding-inline-start: 40px;
      }

      @include desktop {
        max-width: 50%;
        justify-content: flex-end;
      }
    }

    .toolsImage {
      display: none;
      max-width: 50%;
      height: 446px;
      position: relative;
      @include desktop {
        display: block;

        margin-top: 30px;
        margin-bottom: 30px;
        margin-inline-start: 40px;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 665px;
        object-position: left;
        object-fit: cover;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 85px;
        pointer-events: none;
        background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(10, 10, 10, 0.7), rgba(0, 0, 0, 0));
      }
    }
  }

  --header-full-height: calc(var(--height-header) + var(--height-app-banner));
  display: flex;
  justify-content: center;
  padding-top: calc(8px + var(--header-full-height));
  @include tablet {
    padding-top: calc(24px + var(--header-full-height));
  }
  @include desktop {
    padding-top: var(--header-full-height);
  }
}

.ctaInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;

  .wrapperLogoTrustpilot {
    display: flex;
    align-items: center;
    gap: 4px;

    p {
      font-family: Roboto, sans-serif;
    }

    .wrapperStar {
      width: 20px;
      height: 20px;

      img,
      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  p {
    color: var(--palette-white);
  }

  @include tablet {
    justify-content: initial;
  }
}

.usersWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.bottomInfo {
  margin-top: 16px;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding-inline-end: 16px;
  gap: 48px;

  @include tablet {
    padding-inline-end: 40px;
  }

  @include desktop {
    padding-inline-end: 0;
    margin-top: 141px;
    gap: 10px;
    justify-content: space-between;
  }
}

.awardContent {
  display: none;
  align-items: center;
  gap: 16px;
  @include tablet {
    display: flex;
  }

  img {
    max-width: 56px;
    height: auto;
  }
}

.wrapperBottomInfo {
  display: none;

  .bottomInfo {
    margin: 0;
    padding: 16px 0;
    gap: 48px;
  }

  @include desktop {
    display: flex;
  }
}

html[lang='en'] {
  .titleBr {
    @include rwd(1280) {
      display: none;
    }
  }
}

[dir='rtl'] {
  .wrapperLogoTrustpilot {
    flex-direction: row-reverse;
  }
}

.infoSlot {
  display: flex;
  gap: 16px;
  align-items: center;

  flex-direction: column;
  justify-content: center;
  @include tablet {
    flex-direction: row;
    justify-content: flex-start;
  }

  .disclaimer {
    padding-inline-end: unset;

    border-inline-end: none;

    @include tablet {
      padding-inline-end: 16px;

      border-inline-end: 1px solid var(--palette-gray-500);
    }
  }
}
