@import '@/styles/breakpoints.module';

.quoteWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--palette-gray-100);
  border-radius: var(--palette-border-radius-l);

  gap: 16px;
  padding: 24px 16px;

  @include tablet {
    gap: 40px;
    padding: 60px 40px;
  }

  @include desktop {
    padding: 60px 100px;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--palette-white);

  background-color: var(--palette-primary-400);
  border-radius: var(--palette-border-radius-m);

  width: 56px;
  height: 56px;

  svg {
    width: 32px;
    height: 32px;
  }

  @include tablet {
    width: 72px;
    height: 72px;

    svg {
      width: 48px;
      height: 48px;
    }
  }
}

.quoteText {
  text-align: center;
  font-style: italic;
  color: var(--palette-gray-900);
}
