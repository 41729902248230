@import '@/styles/breakpoints.module';

.sampleWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
}

.sampleTextWrapper {
  width: 100%;

  @include desktop {
    width: 50%;
  }
}

.sampleTextWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  @include tablet {
    gap: 24px;
  }

  @include desktop {
    max-width: 458px;
  }
}

.sampleCardWrapper {
  display: block;
  padding: 0;
  height: 100%;

  @include desktop {
    padding: 14px 0;
    display: flex;
    align-items: center;
  }
}

.cardBody {
  display: flex;
  padding: 16px;
  gap: 16px;

  flex-direction: column;

  border-radius: 12px;

  border: 1px solid var(--palette-primary-700);
  background-color: var(--palette-white);

  box-shadow: 0 4px 0 0 rgba(55, 96, 255, 0.5);

  @include tablet {
    padding: 32px;
    gap: 24px;
  }
}

.cardHeading {
  display: flex;
  gap: 12px;
}

.imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--palette-primary-700);

  svg {
    width: 24px;
    height: 24px;

    @include tablet {
      width: 32px;
      height: 32px;
    }
  }
}

.example,
.accent {
  font-weight: var(--palette-font-weight-semibold);
}

.sampleCardWrapper ul {
  display: flex;
  flex-direction: column;
  gap: 6px;

  li {
    color: var(--palette-gray-900);
  }

  svg {
    min-width: 4px;
  }

  :first-child {
    margin-top: 0;
  }
}

.spacing {
  margin-bottom: 80px;

  @include tablet {
    margin-bottom: 96px;
  }

  @include desktop {
    margin-bottom: 200px;
  }
}
