@import '@/styles/breakpoints.module';

.cardWrapper {
  border-radius: var(--palette-border-radius-s);
  border: 1px solid var(--palette-gray-125);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-wrap: anywhere;
}

.avatar {
  max-width: 90px !important;
  min-width: 90px !important;
}

.bioMainBlock {
  display: flex;
  gap: 19px;
  & > :last-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .strategyName {
      font-weight: var(--palette-font-weight-semibold);
      img,
      svg {
        display: inline;
        width: 16px;
        height: 16px;
        margin-inline-start: 4px;
      }
    }

    .followersCount {
      font-weight: var(--palette-font-weight-semibold);
      span {
        font-weight: var(--palette-font-weight-regular);
        color: var(--palette-gray-400);
      }
    }
  }
}
