@import '@/styles/breakpoints.module';

.container {
  color: var(--color-text-primary);
  margin: 0 auto;
  max-width: var(--container-max-width);
  @include tablet {
    padding-inline: var(--container-padding);
  }
}

.heading {
  margin-bottom: var(--vstack-spacing-sm);
  @include tablet {
    margin-bottom: var(--vstack-spacing-md);
  }
  @include desktop {
    margin-bottom: var(--vstack-spacing-lg);
  }
}

.splidePossibility {
  display: block;
  @include tablet {
    display: none;
  }
}

.disclaimer {
  margin-top: var(--vstack-spacing-sm);
  text-align: center;

  @include tablet {
    margin-top: var(--vstack-spacing-md);
  }
  @include desktop {
    margin-top: var(--vstack-spacing-lg);
  }
}
