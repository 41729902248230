@import '@/styles/breakpoints.module';

.stack {
  display: inline-flex;

  img,
  svg {
    border: 1.5px solid transparent;
    border-radius: 100%;
  }
}

.sizeS {
  padding-inline-start: 8px;

  img,
  svg {
    margin-inline-start: -8px;
    width: 24px;
    height: 24px;
  }
}

.sizeML {
  padding-inline-start: 20px;

  img,
  svg {
    margin-inline-start: -20px;
    width: 40px;
    height: 40px;
    @include tablet {
      width: 56px;
      height: 56px;
    }
  }
}
