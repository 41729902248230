@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 32px;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
}

.mapContainer {
  max-height: 416px;
  height: 416px;

  @include desktop {
    min-width: 653px;
    height: 416px;
  }

  background-color: var(--palette-gray-100);
}

.workingInfoWrapper {
  max-width: 427px;
  width: 100%;
}

.workingInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include tablet {
    gap: 24px;
  }

  @include desktop {
    max-width: 427px;
    gap: 32px;
  }
}

.infoCredits {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.addressTitle,
.workingDaysTitle,
.operatingHoursTitle,
.emailTitle {
  font-weight: var(--palette-font-weight-semibold);
}

.emailLink {
  @include typography.subheadingS_;
  @include tablet {
    @include typography.subheadingM_;
  }
}
