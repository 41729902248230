@import '@/styles/breakpoints.module';

.cards {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }

  .sep {
    min-height: 1px;
    max-height: 1px;
    width: 100%;
    margin: 16px 0;
    background-color: var(--color-one-row-benefits-separator);
    @include tablet {
      width: 1px;
      min-height: 100%;
      max-height: 100%;
      margin: 0 8px;
    }

    @include desktop {
      margin: 0 16px;
    }
  }

  .card {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
      padding-bottom: 30px;
      padding-top: 30px;
    }

    .cardBody {
      @include desktop {
        width: max(218px, 90%);
      }
    }

    .cardIconContainer {
      display: block;
      max-width: 64px;
      width: 100%;
      height: auto;
      margin-bottom: 12px;

      svg,
      img {
        width: 100%;
        height: auto;
      }

      @include tablet {
        max-width: 80px;
      }
    }

    h3 {
      margin-bottom: 4px;
      @include tablet {
        margin-bottom: 8px;
      }
    }
  }
}
