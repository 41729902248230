@import '@/styles/breakpoints.module';

.heading {
  margin-bottom: 24px;
  @include tablet {
    margin-bottom: 40px;
  }
}

.sliderContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  .desktopSlider {
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      margin-top: 24px;
      @include desktop {
        margin-top: 16px;
      }
    }

    .dots {
      display: none;
      @include tablet {
        display: flex;
        margin-top: 70px;
      }
    }

    :global(.splide) {
      margin-top: 30px;
    }

    :global(.splide__track) {
      border-radius: 100%;
    }
  }

  .mobileSlider {
    width: 100%;
    margin-top: 54px;
    @include tablet {
      display: none;
    }
    .dots {
      margin-top: 24px;
    }
  }

  .slide {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.progressContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
  max-width: 320px;
  width: 100%;
  top: 0;

  circle {
    stroke-width: 30px;
  }

  @include tablet {
    height: 570px;
    max-width: 570px;
  }
}

.motionSvg {
  transform: rotate(80deg);
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 272px;
  height: 272px;
  position: relative;
  //padding: 16px 16px 24px;

  text-align: center;

  .imgWrapper {
    width: 228px;
    height: 127px;
    @include tablet {
      width: 360px;
      height: 200px;
    }
  }

  @include tablet {
    width: 506px;
    height: 506px;
    //padding: 24px 16px;
  }

  @include desktop {
    //padding: 24px;
  }
}

.content {
  text-align: center;

  .refferals {
    color: var(--color-subtitle);
    margin-top: 12px;

    max-width: unset;
    @include tablet {
      max-width: 360px;
    }

    .referralAccent {
      color: var(--palette-white);
    }
  }

  .label {
    padding: 4px 16px;
    background: var(--color-btn-primary-bg);
    border-radius: var(--palette-border-radius-xxxl);
    text-align: center;
    color: var(--color-text-primary);
    margin-top: 12px;
    @include tablet {
      margin-top: 16px;
    }
    display: inline-block;
  }
}

.contentDesktop {
  display: none;
  @include tablet {
    display: block;
  }
}
