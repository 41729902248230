@import '@/styles/breakpoints.module';

.cardsGridSection {
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-top: var(--section-padding-sm);

  @include tablet {
    gap: 24px;
  }

  h2,
  h3 {
    color: var(--color-text-primary);
  }

  header {
    display: flex;
    justify-content: space-between;
  }
}

.layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  --row-gap: 16px;
  --col-gap: 16px;
  --col-count: 1;

  @include tablet {
    --col-count: 3;
  }

  @include desktop {
    --col-count: 3;
  }

  row-gap: var(--row-gap);
  column-gap: var(--col-gap);

  & > * {
    // cards width = (100% or 50% or 33%) - gaps
    // cardWidthPct = (100% or 50% or 33%) - ((gap * gaps count) / cols count)
    flex-basis: calc(
      (100% / var(--col-count)) - ((var(--col-gap) * (var(--col-count) - 1)) / var(--col-count))
    );
    flex-grow: 1;
  }
}

.card {
  border-radius: var(--palette-border-radius-m);
  background: var(--promo-card-bg, var(--palette-gray-700));
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.15s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: inset 0 0px 2px 0px var(--promo-card-border);
    filter: brightness(1.2) saturate(105%);
  }

  &:active {
    transform: none;
  }

  @include tablet {
    flex-direction: column;
  }

  @include desktop {
    flex-direction: row;
  }

  .cardContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;

    padding: 16px;
    padding-inline-end: 0;

    @include tablet {
      padding: 16px;
      padding-bottom: 0;
    }

    @include desktop {
      padding: 16px;
      padding-inline-end: 0;
    }

    .mainCardContent {
      width: 100%;
    }

    .cta {
      display: inline-block;
      color: var(--color-text-primary);
    }
  }

  .cardImageContainer {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @include desktop {
      width: auto;
    }

    img {
      max-width: 120px;
      min-width: 120px;
      height: auto;
    }
  }

  .contestCardStats {
    color: var(--color-text-primary);
    margin-top: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;

    --gap: 7px;
    --sep-width: 1px;
    --icon-extra-inner-space: 1.5px;

    .contestCardStatsItem {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-inline-end: calc((var(--gap) * 2) + var(--sep-width));
      }

      &:not(:first-child):before {
        content: '';
        display: block;
        height: 14px;
        width: var(--sep-width);
        background-color: var(--palette-gray-500);
        margin-inline-start: calc(-1 * (var(--gap) + var(--sep-width)));
        margin-inline-end: var(--gap);
      }

      & > svg {
        color: var(--palette-yellow-400);
        min-width: 16px;
        max-width: 16px;
        min-height: 16px;
        max-height: 16px;

        &:first-child {
          margin-inline-start: calc(-1 * var(--icon-extra-inner-space));
          margin-inline-end: calc(4px - var(--icon-extra-inner-space));
        }
      }
    }
  }
}
