@import '@/styles/breakpoints.module';

.ctaPart {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;

  @include tablet {
    margin-bottom: 24px;
  }

  a {
    width: 100%;
  }

  .qrCodeButtons {
    display: none;
    gap: 12px;
    margin-inline-start: 16px;

    .apkWrapper {
      width: 56px;
      height: 56px;
    }

    .qrCodeButton {
      width: 56px;
      height: 56px;
      padding: 12px;
      border-radius: var(--palette-border-radius-xs);
      border: 1px solid var(--palette-gray-500);
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: var(--color-btn-social-outline-hover-border);

        .qrPopup {
          opacity: 1;
          visibility: visible;
          //pointer-events: auto;
        }
      }

      .qrPopup,
      .qrPopup {
        position: absolute;
        min-width: 201px;
        width: 100%;
        z-index: 1;
        bottom: -2px;
        inset-inline-start: 100%;
        opacity: 0;
        visibility: hidden;
        transition:
          opacity 0.3s ease,
          visibility 0.3s ease;
        text-align: center;
        pointer-events: none;
        padding-inline-start: 9px;

        .qrPopupInner {
          padding: 16px;
          border-radius: var(--palette-border-radius-m);
          background: var(--palette-gray-700);

          img {
            margin-top: 16px;
          }

          p {
            color: var(--palette-white);
          }
        }
      }
    }

    @include desktop {
      display: flex;
    }
  }

  @include tablet {
    width: initial;

    a {
      width: initial;
    }
  }
}

.ctaButton {
  white-space: nowrap;
}
