@import '@/styles/breakpoints.module';

.widgetsGrid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include tablet {
    align-items: flex-start;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
  }
}

.widgetsGridSidebar {
  display: flex;
  gap: 16px;
  flex-direction: column;
  @include tablet {
    flex-shrink: 2;
    gap: 30px;
    max-width: 360px;
    width: 100%;
  }
}
