@import '@/styles/breakpoints.module';

.section {
  height: 99vh;

  & > div {
    height: 100%;
  }
}

.pageContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;

  @include tablet {
    //--bg-size: 1100px;
    //background: radial-gradient(rgb(46 64 254 / 7%) 0%, rgba(87, 113, 254, 0) 50%) no-repeat center;
    //background-size: var(--bg-size) var(--bg-size);
  }

  @include desktop {
    //--bg-size: 1300px;
  }

  img {
    margin-bottom: 60px;
    width: 100%;
    height: auto;
    max-width: 336px;
    @include tablet {
      max-width: 614px;
    }
  }

  .ctaContainer,
  .ctaContainerVertical {
    a,
    button {
      width: 100%;
      @include tablet {
        width: auto;
      }
    }
  }

  .ctaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    @include tablet {
      flex-direction: row;
    }
  }

  .ctaContainerVertical {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }
}

.btnsColumn {
  display: flex;
  flex-direction: column;

  button,
  a,
  span {
    margin-bottom: 16px;
  }
}
