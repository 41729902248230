@import '@/styles/breakpoints.module';

.mainPanel {
  padding: 24px 12px;

  @include tablet {
    padding: 16px;
  }

  @include desktop {
    padding: 24px 8px;
  }
}

.tabsContainer {
  padding: 0;

  @include tablet {
  }
  @include desktop {
  }
}
