@import '@/styles/breakpoints.module';

@mixin cardMargin {
  margin-top: 24px;
  @include desktop {
    margin-top: 40px;
  }
}

.feesCard {
  background-color: var(--color-fees-card-bg);
  border-radius: var(--border-radius-fees-card);
  padding: 16px;
  width: 100%;

  @include tablet {
    padding: 40px;
  }

  @include desktop {
    max-width: 555px;
    padding: 60px 24px;
  }
}

.feesCardContent {
  @include desktop {
    max-width: 435px;
    margin: 0 auto;
    width: 100%;
  }
}

.feesCardCategoryTabs {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.feesCardTradingFeeContainer {
  @include cardMargin;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--color-fees-card-main-condition-border);
  background: var(--color-fees-card-main-condition-bg);
  display: flex;
  width: 100%;

  @include tablet {
    padding: 16px 24px;
    max-width: 435px;
  }

  @include desktop {
    padding: 16px 16px;
  }
}

.feesCardTradingFeeContent {
  display: flex;
  align-items: flex-end;
  gap: 4px;

  @include tablet {
    gap: 12px;
  }
}

.feesCardTradingFeePct {
  font-size: 62px;
  font-weight: var(--font-weight-fees-card-main-condition-value);
  line-height: 72px;
  color: var(--color-fees-card-main-condition-value);
  @include tablet {
    font-size: 90px;
    font-style: normal;
    line-height: 90px;
  }
}

.feesCardTradingFeeDescription {
  margin-bottom: 8px;
}

.feesCardTradingConditions {
  width: 100%;
  @include cardMargin;

  dl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 16px;

    & > div {
      text-align: start;

      & > :first-child {
        margin-bottom: 2px;
      }
    }

    dd {
      color: var(--color-text-primary);
      font-weight: var(--font-weight-fees-card-condition-name);
    }

    dt {
      color: var(--color-fees-card-condition-value);
    }
  }
}
